import React from 'react'
import ArticlesSection from '../components/sections/ArticlesSection'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import WeDoCtoBanner from '../components/sections/WeDoCtoBanner'

const CorporateResponsibility = ({
  data: {
    contentfulPage: pageData,
    allContentfulArticle: { nodes: articlesData },
  },
}) => {
  const {
    relatedPolicy: {
      lastUpdated,
      content: { raw: rawContent },
    },
  } = pageData

  return (
    <Layout page={pageData} socialIcons showProgress showBelow={300}>
      <div className='container px-8'>
        <p>Last updated {lastUpdated}</p>
        {documentToReactComponents(JSON.parse(rawContent))}
      </div>
      {/*<WeDoCtoBanner />*/}
      <ArticlesSection title='Latest' articlesData={articlesData} />
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulPage(pageLink: { eq: "/corporate-social-responsibility" }) {
      ...pageFields
    }
    allContentfulArticle(limit: 3, sort: { fields: publishDate, order: DESC }) {
      nodes {
        ...articleFields
      }
    }
  }
`

export default CorporateResponsibility
